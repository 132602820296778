import React, { useEffect, useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../../../structure/Layout/Layout";
import Seo from "../../../structure/Seo/Seo";
import Editor from "../../../element/Editor/Editor";
import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";
import CitySkyline from "../../../element/Svg/CitySkyline";

const ProjectPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
    clearLSFilters();
  }, []);

  return (
    <Layout current='home'>
      <Seo
        title="Bienvenue sur Vu des Quartiers"
        description={`"Vu des Quartiers" est un annuaire de référents dans les quartiers prioritaires, habitants et contacts, pour renouer un lien avec la presse et faire remonter l’information directement des quartiers. "Vu des Quartiers" est une initiative cofondée par l’association des maires Ville & Banlieue et BFMTV, et gérée par des rédactions de presse.`}
      />
      <div className="page page-grid page-cold page-landingpage" data-theme="primary-25">
        <div className="page_inner">
          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Le site arrive bientôt</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>
          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">
              <Editor content={`
              <div class="editor-block">
                <p>
                Un constat encore trop fréquent
                </p>

                <blockquote>
                  <p>
                  Je ne reconnais pas ma ville…<br>
                  Je ne reconnais pas mon quartier...<br>
                  Si on m’avait demandé...
                  </p>
                </blockquote>
              </div>

  

              <div class="editor-paragraph">
                <h2>L’annuaire, une réponse en plus</h2>
                <p>
                <b>« Vu des Quartiers » met en valeur</b> des contacts habitant et/ou agissant dans les quartiers prioritaires.
                </p>
                <p>
                  <b>C’est un outil.</b> Une façon de créer un lien entre les journalistes et les habitants ou acteurs de ces quartiers qui veulent échanger avec la presse, être des interlocuteurs, raconter ce qui se passe chez eux.
                </p>
                <p>
                <b>C’est un annuaire</b> dans lequel sont référencées les personnes volontaires pour parler de leur quartier. L’annuaire n’est accessible que par les journalistes accrédités, qui contactent les habitants et acteurs dans le cadre de leur travail.
                </p>
              </div>

              <div class="editor-paragraph">
                <h2>Comment mieux faire ?</h2>
                <p>
                  <b>Les journalistes ne peuvent pas agir sans le témoignage des habitants et acteurs des quartiers</b>, qui connaissent mieux que personne ce qui s’y passe.
                </p>
                <p>
                Il ne s’agit pas de dire que tout va bien ou mal, que tout fonctionne ou pas. Il s’agit de parler plus justement et de façon plus équilibrée de ce qui se passe au quotidien.<br> 
                <b>Un portrait plus efficace de l’actualité et de la vie dans les quartiers.</b>
                </p>
              </div>

              <div class="editor-paragraph">
                <h2>Mais où, justement ?</h2>
                <p>
                Les quartiers prioritaires sont des territoires définis par l’État pour mieux cibler son action. <b>L’annuaire couvrira l’ensemble des 1514 quartiers prioritaires de la politique de la ville</b>, sur l’ensemble du territoire national, dans l’Hexagone et en Outremer.
                </p>
                <p>
                Les quartiers, ce n’est pas que la banlieue. Ce n’est pas que l’Île-de-France. C’est toute la France.
                </p>
              </div>

              <div class="editor-paragraph">
                <h2>Faire émerger les récits, faire entendre votre voix</h2>
                <p>
                  <b>Un annuaire pour parler plus justement de ce qui marche, ce qui ne marche pas.</b> Et pour faire émerger les récits, les histoires, le quotidien. Vos récits, vos histoires. Votre vie au quotidien. 
                </p>
              </div>
              
            `} />

              <div className="cta-wrapper">
                <AniLink className="btn btn-cta btn-secondary rounded"
                  fade
                  to={'/membre'}
                  entry={{
                    delay: 0.5
                  }}
                >
                  <span className="btn_label">Devenir un interlocuteur pour mon quartier</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </AniLink>

              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default ProjectPage